import { _ } from "vue-underscore";
import Vue from 'vue';
export default {
  data: () => ({
    landingCountry: {
      id: 0,
      url_slug: "",
      title: "",
      meta_description: "",
      meta_title: "",
      head_scripts: "",
      meta_keywords:"",
      body_scripts: "",
      logoImage: "",
      logo_url: "",
      image_url: "",
    },
    loading: false,
		webUrl: process.env.VUE_APP_WEB_URL,
    selectedRows: [],
    airport: [],
    images: [],
    files: [],
    countries: [],
    error: "",
    backUrl: "/landing-countries"
  }),
  methods: {
    resetForm() {
      this.$refs.observer.reset();
      this.landingCountry = {
        id: 0,
        url_slug: "",
        title: "",
        meta_description: "",
        meta_title: "",
        head_scripts: "",
        body_scripts: "",
        logoImage: "",
        logo_url: "",
      };
    },

    validatelandingCountry() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
      this.landingCountry.id > 0 ? this.update() : this.add();
    },

    //Update Landing Country Deatils and Multiple Images
    update() {
      let _vm = this;
      let fd = new FormData();
      if (_vm.landingCountry.logoImage) {
        fd.append("logoImage", _vm.landingCountry.logoImage);
      }
      if(this.images && this.images.length > 0){
        this.images.forEach(element => {
          fd.append("multipleImages[]", element);
        });
      }
      Object.keys(_vm.landingCountry).forEach(key => {
        fd.append(key, _vm.landingCountry[key]);
      });
      fd.append("_method", "PATCH");
      this.axios
        .post("/landing-country/" + _vm.landingCountry.id, fd, {
          ...this.landingCountry
        })
        .then(function (response) {
          _vm.landingCountry = response.data.data;
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () { });
    },

    onFileChange(e) {
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.images.push(selectedFiles[i]);
      }
      for (let i = 0; i < this.images.length; i++) {
        let reader = new FileReader(); //instantiate a new file reader
        reader.addEventListener('load', function () {
          this.$refs['image' + parseInt(i)][0].src = reader.result;
        }.bind(this), false);  //add event listener

        reader.readAsDataURL(this.images[i]);
      }
    },
    removeImage(id) {
      let _vm = this;
   		this.$dialog
				.confirm("Are you sure you want to delete this record?")
				.then(function() {
					_vm.axios
						.delete("landing-country-image-delete/" + id)
						.then(function() {
							_vm.getDetail();
						})
						.catch(function(error) {
						});
				})
				.catch(function(error) {
				});
		},
    selectImage(event) {
      let _vm = this;
      var input = event.target;
      if (input.files && input.files[0]) {
        _vm.landingCountry.logoImage = input.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#imagePreview").css(
            "background-image",
            "url(" + e.target.result + ")"
          );
          $("#imagePreview").hide();
          $("#imagePreview").fadeIn(650);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },


    // Store Landing Country Deatils and Multiple Images
    add() {
      let _vm = this;
      let fd = new FormData();
      //Singele image store
      if (_vm.landingCountry.logoImage) {
        fd.append("logoImage", _vm.landingCountry.logoImage);
      }
      //Singel Image End

      // Multiple Image Store
      if(this.images && this.images.length > 0){
        this.images.forEach(element => {
          fd.append("multipleImages[]", element);
        });
      }

      Object.keys(_vm.landingCountry).forEach(key => {
        fd.append(key, _vm.landingCountry[key]);
      });
      this.axios.post("/landing-country", fd).then(function (response) {
        _vm.$router.push(_vm.backUrl);
      });
    },

    // View Landing Country Deatils and Multiple Images
    getQueryString() {
      let queryString = "?search=";
      return queryString;
    },
    getDetail() {
      let _vm = this;
      this.axios
        .get("/landing-country/" + this.$route.params.id)
        .then(function (response) {
          _vm.landingCountry = response.data.data;

          if (
            _vm.landingCountry.full_image_url &&
            _vm.landingCountry.full_image_url.length > 0
          ) {
            $("#imagePreview").css(
              "background-image",
              "url(" +
              _vm.landingCountry.full_image_url +
              "?date=" +
              Date.now() +
              ")"
            );
            $("#imagePreview").hide();
            $("#imagePreview").fadeIn(650);
          }

        })
        .catch(function () { });
    },
    getCountryAndCountryCode() {
      let _vm = this;
      this.axios
        .get("/airport-list-by-country", _vm.countries)
        .then(function (response) {
          _vm.countries = response.data.data;
          if (parseInt(_vm.$route.params.id) > 0) {
            _vm.getDetail();
          }
        })
        .catch(function () { });
    },
    updateUrl(event){
      if(event.code === 'Space'){
        if(this.landingCountry.url_slug.length>1 && this.landingCountry.url_slug[this.landingCountry.url_slug.length-2]==='-'){
          this.landingCountry.url_slug =  event.target.value.replace(/\s{1,}/g, '');
        }else{
         this.landingCountry.url_slug =  event.target.value.replace(/\s{1,}/g, '-');
        }
      }

    },
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.getDetail();
    }
   
    this.getCountryAndCountryCode();
  }
};
